<template>

    <q-layout view="hHh lpr fFf" id="piattaforma-rami">

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="sta_caricando"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <q-page-container style="padding-left: 16px; padding-right: 16px;">
            <br>
            <div class="row justify-center">

                <div class="col-12 col-md-12" align="center" v-if="isAby">
                    <q-img
                        style="width: 300px;"
                        :src="path_logo" />
                </div>

                <div class="col-12 col-md-12" align="center" v-if="!isAby">
                    <h3>{{ getRagioneSocialeUfficio }}</h3>
                </div>


                <div class="col-12 col-md-8">

                    <br>
                    <q-toolbar class="bg-primary text-white shadow-2">
                        <q-toolbar-title><div align="center">BOX CONTRATTUALE</div></q-toolbar-title>
                    </q-toolbar>
                    <q-list bordered>
                        <q-item>
                            <q-item-label class="text-h5">{{ getNominativoCliente }}</q-item-label>
                        </q-item>
                        <q-item>
                            <q-item-label><strong>Contratto N.</strong> {{ getNumeroPreventivo() }}</q-item-label>
                        </q-item>
                    </q-list>

                    <q-expansion-item
                        expand-separator
                        label="IL MIO ASSICURATORE"
                        v-model="mio_assicuratore"
                        class="bg-primary text-white text-h6 q-mb-lg"
                    >
                        <div style="background-color: #fff; color: #000;" class="text-left font-size: 14px;">
                            <q-list bordered>
                                <q-item>
                                    <q-item-section>
                                        <q-item-label caption lines="1">IL MIO CONSULENTE PERSONALE</q-item-label>
                                        <q-item-label>{{ getIntermediario }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item>
                                    <q-item-section>
                                        <q-item-label caption lines="1">LA SUA SEZIONE RUI</q-item-label>
                                        <q-item-label>{{ getRUIIntermediario.numero_rui }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item>
                                    <q-item-section>
                                        <q-item-label caption lines="1">TELEFONO</q-item-label>
                                        <q-item-label>{{ getRecapitiIntermediario.telefono }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item>
                                    <q-item-section>
                                        <q-item-label caption lines="1">EMAIL</q-item-label>
                                        <q-item-label>{{ getRecapitiIntermediario.email }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </div>
                    </q-expansion-item>

                    <q-expansion-item
                        expand-separator
                        label="DOCUMENTI DA LEGGERE"
                        class="bg-primary text-white text-h6 q-mb-lg">
                        <div style="background-color: #fff; color: #000;" class="text-left text-h7">
                            <br>
                            <q-list bordered>
                                <q-item clickable v-ripple
                                    style="background-color: #ffffc0; font-size: 18px;" class="q-mb-sm"
                                    v-for="(altro,index) in documenti_allegati.documenti"
                                    v-bind:key="index">

                                    <q-item-section
                                        @click="ScaricaDocumento(altro.path,altro.id)">
                                        <strong>{{ altro.descrizione }}</strong>
                                    </q-item-section>

                                    <q-item-section avatar>
                                        <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                    </q-item-section>

                                </q-item>
                            </q-list>
                        </div>
                    </q-expansion-item>

                    <q-expansion-item
                        expand-separator
                        v-model="miei_contratti"
                        label="I MIEI CONTRATTI"
                        class="bg-primary text-white text-h6 q-mb-lg"
                    >
                        <div style="background-color: #fff; color: #000;" class="text-left text-h7">
                            <br>

                            <div v-for="(prodotto,index) in elenco_contratti"
                                v-bind:key="index">

                                <fieldset>
                                    <legend>{{ prodotto.label_prodotto }}</legend>
                                    <br>
                                    <q-list bordered style="font-size: 16px;">
                                        <q-item>
                                            <q-item-section>
                                                <q-item-label caption lines="1">FRAZIONAMENTO</q-item-label>
                                                <q-item-label>{{ prodotto.frazionamento.toUpperCase() }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item>
                                            <q-item-section>
                                                <q-item-label caption lines="1">COMPAGNIA</q-item-label>
                                                <q-item-label>{{ prodotto.compagnia.replaceAll("_"," ") }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item>
                                            <q-item-section>
                                                <q-item-label caption lines="1">NUMERO POLIZZA</q-item-label>
                                                <q-item-label>{{ prodotto.numero_polizza }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                    <br>
                                    <q-list bordered style="font-size: 16px;" v-if="documenti_contratti.length > 0">
                                        <q-item clickable v-ripple style="background-color: #ffffc0;" class="q-mb-sm">
                                            <q-item-section
                                                @click="ScaricaDocumento(documenti_contratti[index].url,documenti_contratti[index].filename)">
                                                <q-item-label caption lines="1">POLIZZA</q-item-label>
                                                <q-item-label><strong>{{ documenti_contratti[index].filename }}</strong></q-item-label>
                                            </q-item-section>
                                            <q-item-section avatar>
                                                <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                            </q-item-section>
                                        </q-item>

                                        <div v-for="(documento_catalogo,indexCatalogo) in documenti_catalogo[index].documenti"
                                                    v-bind:key="indexCatalogo">
                                            <q-item clickable v-ripple style="background-color: #ffffc0;" class="q-mb-sm">
                                                <q-item-section
                                                    @click="ScaricaDocumento(documento_catalogo.path,documenti_contratti[index].filename)">
                                                    <q-item-label><strong>{{ documento_catalogo.descrizione }}</strong></q-item-label>
                                                </q-item-section>
                                                <q-item-section avatar>
                                                <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                            </q-item-section>
                                            </q-item>
                                        </div>

                                    </q-list>
                                </fieldset>
                                <br>
                            </div>
                        </div>
                    </q-expansion-item>

                    <q-expansion-item
                        expand-separator
                        label="RESOCONTO ECONOMICO"
                        class="bg-primary text-white text-h6 q-mb-lg"
                    >
                        <div style="background-color: #fff; color: #000;" class="text-left text-h7">
                            <br>
                            <table class="table table-bordered" style="font-size: 18px;">
                            <thead>
                                <tr>
                                    <th>Settore di polizza</th>
                                    <th>Compagnia</th>
                                    <th>Numero contratto</th>
                                    <th>Premio Contratto</th>
                                </tr>
                            </thead>
                            <tbody v-for="(prodotto,index) in elenco_contratti"
                                v-bind:key="index">
                                <tr>
                                    <td>{{ prodotto.label_prodotto }}</td>
                                    <td>{{ prodotto.compagnia }}</td>
                                    <td>{{ prodotto.numero_polizza }}</td>
                                    <td align="right">{{ prodotto.premio.toFixed(2) }} &euro;</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="3" align="right">CONSULENZA TOTALE:</td>
                                    <td align="right"><strong style="color: #800000;">{{ getConsulenzaTotale() }} &euro;</strong></td>
                                </tr>
                                <tr>
                                    <td colspan="3" align="right"><strong>TOTALE:</strong></td>
                                    <td align="right"><strong style="color: #800000;">{{ getPremioTotale() }} &euro;</strong></td>
                                </tr>
                            </tfoot>
                        </table>
                        </div>
                    </q-expansion-item>

                </div>

            </div>


        </q-page-container>
        <br><br><br><br>
        <Footer />
    </q-layout>

</template>


<script>
    import commonLib from '@/libs/commonLib';
    import api from '@/libs/api';
    import axios from 'axios';
    import { mapActions } from "vuex";

    export default {
        name: "BoxInformativo",
        data() {
            return {
                is_mounted: false,
                id_preventivo: "",
                token: "",
                preventivo: {},
                documenti_catalogo: [],
                documenti_allegati: {},
                documenti_contratti: [],
                elenco_contratti: [],
                miei_contratti: true,
                sta_caricando: false,
                mio_assicuratore: true,
                dati_economici: []
            }
        },
        methods: {
            ...mapActions({
                fetchElencoContrattiPratica: "gestionePratiche/fetchElencoContrattiPratica",
                fetchDatiEconomiciBoxContrattuale: "gestionePratiche/fetchDatiEconomiciBoxContrattuale"
            }),
            trascodificaAzione(azione) {
                switch(azione) {
                    case "COMPILARE_E_FIRMARE":
                        return "COMPILARE E FIRMARE";
                    case "LEGGERE":
                        return "LEGGERE";
                }
            },
            async ScaricaDocumento(url,name) {

                //console.log("name:",name);

                if (url === "Documento non trovato") {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Nessun documento presente nello storage'
                    });
                    return;
                }

                await axios({
                     url:url,
                     method:'GET',
                     responseType: 'blob',
                    headers:{ Authorization:""}
                 })
                 .then((response) => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = "_blank";
                    link.setAttribute('download', name+".pdf");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                 });

            },
            trascodificaData(value) {
                if (!commonLib.isEmpty(value)) {
                    var element = value.split(" ")[0];
                    var data_db = element.split("-");

                    return data_db[2]+"/"+data_db[1]+"/"+data_db[0];
                }

                return "";
            },
            getPropostaSelezionata(prodotto) {
                if (!commonLib.isEmpty(prodotto)) {
                    var proposta_selezionata = prodotto.proposte.filter(proposta => {
                        return proposta.is_checked;
                    });

                    if (proposta_selezionata.length !== 0) {
                        return proposta_selezionata[0];
                    }
                }

                return {};
            },
            getPremioProposta(prodotto) {
                if (!commonLib.isEmpty(prodotto)) {
                    var proposta = prodotto.proposte.filter(p => {
                        return p.is_checked;
                    });
                    var premio = //prodotto.consulenza +
                                 //proposta[0].diritti_aby +
                                 proposta[0].premio;

                    return premio.toFixed(2);
                }

                return 0;
            },
            getConsulenzaTotale() {
                var totale = 0;

                console.log("dati economici:",this.dati_economici);

                if (!commonLib.isEmpty(this.preventivo.json_form)) {

                    this.dati_economici.filter(d => {
                        if ((d.tipo === "DIRITTI_ABY") ||
                           (d.tipo === "COSTO_SERVIZIO")  ||
                           (d.tipo === "CONSULENZA") ||
                           (d.tipo === "DIRITTI_FORNITORE") ||
                           (d.tipo === "DIRITTI_TERZI")
                        ) {
                            totale += parseFloat(d.importo);
                        }

                        return true;
                    })

                    return totale.toFixed(2);
                }

            /*    if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    this.preventivo.json_form.preventivo.prodotti.filter(p => {

                        if (p.is_checked) {
                            var proposte = p.proposte.filter(proposta => {
                                return proposta.is_checked;
                            });
                            var frazionamento = proposte[0].frazionamento;
                            var consulenza = p.consulenza;
                            var diritti = proposte[0].diritti_aby;
                            if (frazionamento.toLowerCase() === "semestrale") {
                                consulenza /= 2;
                                diritti /= 2;
                            }
                            totale += parseFloat(diritti);
                            totale += parseFloat(consulenza);
                            console.log("prodotto:",p.label,p.consulenza,frazionamento);
                        }

                        return p.is_checked;
                    });

                    return totale.toFixed(2);
                } */

                return 0;
            },
            getNumeroPreventivo() {
                if (!commonLib.isEmpty(this.preventivo.json_form))
                    return this.preventivo.json_form.preventivo.id;

                return "";
            },
            getTafiffaProposta(prodotto) {
                var proposta = this.getPropostaSelezionata(prodotto);
                return proposta.tariffa;
            },
            getPremioTotale() {
                var totale = 0;

                if (!commonLib.isEmpty(this.preventivo.json_form)) {

                    this.dati_economici.filter(d => {
                        totale += parseFloat(d.importo);
                        return true;
                    })

                    return totale.toFixed(2);
                }

                return 0;
            },
            async getContrattiSelezionati() {
                var elenco = [];

                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    elenco = await this.fetchElencoContrattiPratica(this.preventivo.json_form.preventivo.id);

                    console.log("elenco contratti:",elenco.contratti,this.preventivo.json_form.preventivo.id);

                    return elenco.contratti;
                }
                console.log("Sono qui");
                return elenco;
            }
        },
        computed: {
            isAby() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    return this.preventivo.json_form.intermediario_capo_gruppo.is_abybroker;
                }

                return false;
            },
            getRagioneSocialeUfficio() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    return this.preventivo.json_form.unita_operativa.nominativo.toUpperCase();
                }

                return "";
            },
            getNominativoCliente() {
                if (!commonLib.isEmpty(this.preventivo)) {
                    return this.preventivo.nominativo.toUpperCase();
                }

                return "";
            },
            getDocumentiCatalogo() {
                //console.log("documenti_catalogo:",this.documenti_catalogo);
                //console.log("documenti_proposta:",this.documenti_allegati);
                return this.documenti_catalogo;
            },
            getDocumentiContratto() {
                return this.documenti_contratti;
            },
            getDataInserimento() {
                var data = this.preventivo.created_at_onlydate_as_string;
                if (!commonLib.isEmpty(data))
                    return data;

                return "";
            },
            getIntermediario() {
                console.log("JSonForm:",this.preventivo.json_form);

                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    return this.preventivo.operatore;
                }

                return "";
            },
            getRUIIntermediario() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    return {
                        data_iscrizione: this.preventivo.json_form.persona_operativa.dati_rui.data_iscrizione,
                        numero_rui: this.preventivo.json_form.persona_operativa.dati_rui.numero_rui
                    };
                }

                return {
                    data_iscrizione: "",
                    numero_rui: ""
                };
            },
            getRecapitiIntermediario() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    return {
                        telefono: this.preventivo.json_form.persona_operativa.telefono,
                        email: this.preventivo.json_form.persona_operativa.email,
                        pec: this.preventivo.json_form.persona_operativa.pec
                    };
                }

                return {
                    telefono: "",
                    email: "",
                    pec: ""
                };
            },
            getProdottiSelezionati() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {

                    var prodotti = this.preventivo.json_form.preventivo.prodotti.filter(p => {
                        // Verifica se ci sono proposte selezionate
                        var proposte = p.proposte.filter(proposta => {
                            return proposta.is_checked;
                        });

                        return ((proposte.length === 1) && (p.is_checked));
                    });

                    return prodotti;
                }

                return [];
            },
            path_logo() {
                return require("@/images/logo_aby.png");
            }

        },
        components: {
        //    Header: () => import("./Header"),
            Footer: () => import("./Footer"),
        },
        async mounted() {
            this.sta_caricando = true;
            this.is_mounted = false;

            // recupera id_preventivo e token da url
            this.id_preventivo = commonLib.getQueryParamFromUrl("id");
            this.token = commonLib.getQueryParamFromUrl("token");

            // Chiede al box-informativo le informazioni della pratica
            //var url =  process.env.VUE_APP_API_URL + "box-informativo/preventivo?id="+this.id_preventivo+"&token="+this.token;
            var url =  process.env.VUE_APP_API_URL + "box-informativo/elenco_documenti?id="+this.id_preventivo+"&token="+this.token;
            var oJSon = await api.getJSonFromController(url);

            if ((commonLib.isEmpty(oJSon)) || (oJSon.request.status !== 200)) {
                this.$router.push({name: "Unauthorized401"});
                return;
            }

            if (oJSon.data.haveError) {
                this.$router.push({name: "NotFound404"});
                return;
            }

            console.log("preventivo:",oJSon.data.preventivo);

            this.preventivo = oJSon.data.preventivo;
            this.documenti_contratti = oJSon.data.documenti_contratti;

            // TODO - Questa istruzione genera un warning di chiave duplicata 0 ma non capisco dove sia
            this.documenti_catalogo = oJSon.data.documenti_catalogo;
            this.documenti_allegati = oJSon.data.documenti_proposta;

            this.elenco_contratti = await this.getContrattiSelezionati();

            this.dati_economici = await this.fetchDatiEconomiciBoxContrattuale(this.preventivo.json_form.preventivo.id);

            this.sta_caricando = false;
            this.is_mounted = true;
        }
    }

</script>

<style scoped>
    td.domanda {
        text-align: right;
        font-style: italic;
        font-weight: bold;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered td, .table th {
        border: 1px solid #404040;
        padding: 8px;
    }

    .table-bordered th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        color: #404040;
        background-color: #F0F0F0;
        border: 1px SOLID #404040;

    }
</style>
